<template>
<Page id="tips-view-games" title="Visar matcher">
      <GTable></GTable>
</Page>
</template>

<script>
const Page = () => import("@/components/common/Page");
const GTable = () => import("@/components/tips/view/games/GamesTable");

export default {
      name: "ViewGames",
      components: {
            Page,
            GTable
      },
      data() {
            return {};
      },
      metaInfo: {
            title: "Visa Matcher",
            meta: [{
                  name: "description",
                  content: "Här är alla matcher för turneringen",
            }, ],
      },     
      mounted() {
            this.$store.dispatch("tipsg/getViewGamesTips");
            this.$store.dispatch("tournamentsg/getTournamentUsers");
      },
};
</script>
